import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { IndexUsers } from '../ManageUsers';
import { UsersAddUpdate } from '../ManageUsers/addupdate';
import { Setup } from '../ParentalCoverage/setup';
import AddSetup from '../ParentalCoverage/AddSetup';
import History from '../ParentalCoverage/History';
import CoveragePurchased from '../ParentalCoverage/CoveragePurchased';

export default function Access12() {
    return (
        <Routes>
            <Route path="/" element={<IndexUsers />} />
            <Route path="/users/*" element={<IndexUsers />} />
            <Route path="/addupdate-users/*" element={<UsersAddUpdate />} />
            <Route exact path="/setup" element={<Setup />} />
            <Route exact path="/add-setup" element={<AddSetup />} />
            <Route exact path="/plan-history" element={<History />} />
            <Route exact path="/purchases" element={<CoveragePurchased />} />
        </Routes>
    )
}
