import React, { useState } from 'react'
import { HideLoder, isValidEmail, ShowLoder } from '../../JS/Common';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CheckValidation } from '../../Components/Validations';
import { ApiPostCallAuthKey } from '../../JS/Connector';
import { useNavigate } from 'react-router-dom';

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [AlertMsgs, setAlertMsgs] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    const [Email, setEmail] = useState("");
    const [emailError, setemailError] = useState("");
    const navigate = useNavigate();
    async function handleForgotPwd() {
        var isFormValid = CheckValidation({ formID: 'forgotpasswordform' });
        if (Email != "") {
            if (!isValidEmail(Email)) {
                setemailError('*Invalid Email')
                isFormValid = false;
            } else {
                setemailError("");
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            email: Email,
        });
        await ApiPostCallAuthKey("/sendResetPasswordMail", raw).then((result) => {
            if (result == undefined || result == "") {
                setAlertColor("red");
                setAlertMsgs("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlertMsgs(`Email sent on ${Email}`);
                    setAlertColor("green");
                    setTimeout(() => {
                        navigate('/');
                    }, 1500);
                } else {
                    setAlertMsgs(responseRs.msg);
                    setAlertColor("red");
                }

            }
        });
    }
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <div className="col-12 text-center">
                            <div style={{ color: `${AlertColor}` }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                        </div>
                        <div className="my-4">
                            <img src="/images/NewLogo.svg" className="img-fluid" alt="Logo" />
                        </div>
                        <div className='col-12 text-center py-2'>
                            <h4 className="fw-600">Forgot Password?</h4>
                        </div>
                        <div className="row parent" id="forgotpasswordform">
                            <div className="col-md-11 offset-md-1">
                                <input type="text" placeholder="Email*" autoComplete="off" name='email'
                                    className="form-control customFormControl" required value={Email} onChange={(e) => setEmail(e.target.value)} />
                                <span className="form-text text-start invalid-feedback">
                                    *required
                                </span>
                                <div className="Emailinvalid text-start">
                                    {emailError}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button className='CreateButton col-md-5' onClick={handleForgotPwd}>Request Password Reset Link</button>
                        </div>
                        <div className="row text-center pt-4">
                            <a className="ps-2 cursor-pointer" style={{ color: "rgb(30 191 162)" }} onClick={(e) => navigate("/")}>Back to Login</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
