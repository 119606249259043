import { useState, useEffect } from "react";
import { MicrosoftLoginForm } from "./MicrosoftLogin";
import { generateAccessToken, HideLoder, isValidEmail, RemoveAllCookies, ShowLoder } from "../../JS/Common";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GoogleOauth from "../../Components/GoogleOauth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ApiPostCallAuthKey } from "../../JS/Connector";
import Cookies from 'js-cookie';
import { CheckValidation } from "../../Components/Validations";
import { useNavigate } from "react-router-dom";
export function Login() {
    const [AlertMsgs, setAlertMsgs] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailError, setemailError] = useState('');
    const navigate = useNavigate();
    const [LoginDetails, setLoginDetails] = useState({
        Email: '',
        Password: ''
    });
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    useEffect(() => {
        RemoveAllCookies();
    }, []);
    const customloginsuccess = async () => {
        var isFormValid = CheckValidation({ formID: 'customloginform' });
        if (LoginDetails.Email != '') {
            if (!isValidEmail(LoginDetails.Email)) {
                setemailError('*Invalid Email')
                isFormValid = false;
            } else {
                setemailError("");
            }
        }
        if (!isFormValid) return;
        setLoading(true)
        var raw = JSON.stringify({
            name: null,
            email: LoginDetails.Email,
            googleId: null,
            microsoftId: null,
            accessToken: generateAccessToken(32),
            flag: 3,
            password: LoginDetails.Password
        });
        await ApiPostCallAuthKey("/UserLogin", raw).then((result) => {
            if (result == undefined || result == "") {
                console.error('Login Failed!');
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false)
                if (responseRs.status == "success") {
                    const expires = 1;
                    Cookies.set('CsvUserId', responseRs.msg.id, { expires });
                    Cookies.set('schoolid', responseRs.msg.school_id, { expires });
                    Cookies.set('ciphertext', responseRs.msg.remember_token, { expires });
                    if (responseRs.schoolDetails != null) {
                        Cookies.set('Schoolnumber', responseRs.schoolDetails.school_number, { expires });
                    }
                    Cookies.set('ShippingType', responseRs?.schoolDetails?.shipping_type, { expires });
                    setAlertColor("green");
                    setAlertMsgs(`Authentication successfull! Redirecting to the home page...`);
                    setTimeout(() => {
                        if (responseRs.msg.access_type == 5 || responseRs.msg.access_type == 6) {
                            window.location = '/school-tickets';
                        } else {
                            window.location.href = '/';
                        }
                    }, 1500);
                }
                else {
                    setAlertColor("red");
                    setAlertMsgs(responseRs.response);
                }
            }

        });
    }
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv" style={{ overflowY: "scroll" }}>
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <div className="col-12 text-center">
                            <div style={{ color: `${AlertColor}` }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                        </div>
                        <div className="my-4">
                            <img src="/images/NewLogo.svg" className="img-fluid" alt="Logo" />
                        </div>
                        <div className="row parent" id="customloginform">
                            <div className="col-md-11 offset-md-1">
                                <input type="text" placeholder="Email*" autoComplete="off" name='email'
                                    className="form-control customFormControl" required value={LoginDetails.Email} onChange={(e) => setLoginDetails({ ...LoginDetails, Email: e.target.value })} />
                                <span className="form-text text-start invalid-feedback">
                                    *required
                                </span>
                                <div className="Emailinvalid text-start">
                                    {emailError}
                                </div>
                            </div>
                            <div className="col-md-11 offset-md-1 mt-3 position-relative">
                                <input type={showPassword ? 'text' : 'password'} placeholder="Password*" autoComplete="off" name='password'
                                    className="form-control customFormControl" required value={LoginDetails.Password} onChange={(e) => setLoginDetails({ ...LoginDetails, Password: e.target.value })} />
                                <span className="form-text text-start invalid-feedback">
                                    *required
                                </span>
                                <span
                                    className="eye-icon"
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: 'pointer', position: 'absolute', right: '4%', top: '11px' }}
                                >
                                    {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                </span>
                                <div className="font-12 text-start cursor-pointer mt-2 ms-2 " onClick={(e) => navigate("/forgot-password")}>
                                    Forgot Password ?
                                </div>
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button className='CreateButton col-md-3' onClick={customloginsuccess}>Log In</button>
                        </div>
                        <div className="col-12 text-center py-3">
                            <img src="/images/NewLoginOr.svg" className="img-fluid" />
                        </div>
                        <div className="col-md-6 text-center mt-3 pe-0">
                            <GoogleOauth alertmsg={setAlertMsgs} alertcolor={setAlertColor} flag="signin" />
                        </div>
                        <div className="col-md-6 text-center mt-3">
                            <MicrosoftLoginForm alertmsg={setAlertMsgs} alertcolor={setAlertColor} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 pt-5 font-13">
                                Register for our demo <a className="cursor-pointer" style={{ color: "rgb(30 191 162)" }} href="https://k12tech.clickmeeting.com/k12tech-rocket-demo/register" target="_blank">Here</a>
                            </div>
                            <div className="col-md-6  pt-5 font-13">
                                Need an account?<a className="ps-2 cursor-pointer" style={{ color: "rgb(30 191 162)" }} href="/register">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
