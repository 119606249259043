import React, { useEffect, useState } from 'react'
import { ApiGetCallAuthKey, ApiPostCallAuthKey } from '../../JS/Connector';
import { HideLoder, isValidEmail, ShowLoder } from '../../JS/Common';
import { CheckValidation } from '../../Components/Validations';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function YesContractOption(props) {
    const [Loading, setLoading] = useState(false);
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [studentData, setStudentData] = useState({
        studentnum: '',
        firstname: "",
        lastname: '',
        parentfirstname: '',
        parentlastname: '',
        phoneno: '',
        email: ''
    })
    var captchasitekey = process.env.REACT_APP_Captcha_Sitekey;
    const [CaptchaKey, setCaptchaKey] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [PayLinkBtnShow, setPayLinkBtnShow] = useState(false)
    const [DisabledData, setDisabledData] = useState(false)
    const [ValidateError, setValidateError] = useState('');
    const [ValidateColor, setValidateColor] = useState('');
    const [TempId, setTempId] = useState('');
    const [StudentNo1, setStudentNo1] = useState('')
    const [AccessCode, setAccessCode] = useState('')
    const [ErrormsgFormidYear, setErrormsgFormidYear] = useState("");
    const [studentNumberFlag, setStudentNumberFlag] = useState("");
    const [CutPriceFlag, setCutPriceFlag] = useState("");
    const [ActualPrice, setActualPrice] = useState('');
    const [studentnumerror, setstudentnumerror] = useState("")
    const [nostudenterror, setnostudenterror] = useState("")
    const [Emailerror, setEmailrror] = useState("")
    const [disableInputs, setdisableInputs] = useState(true)
    useEffect(() => {
        setTotalPrice(props?.price)
    }, [])
    function clearStudentData() {
        setdisableInputs(true)
        setStudentData({
            ...studentData,
            firstname: "",
            lastname: '',
            parentfirstname: '',
            parentlastname: '',
            phoneno: '',
            email: ''
        })
    }
    async function Searchfunction(searchkey) {
        if (searchkey == "") {
            searchkey = null;
        }
        if (searchkey) {
            setLoading(true)
            await ApiGetCallAuthKey("/StudentDataByStudentNum/" + searchkey + '/' + props.SchoolId).then((result) => {
                if (result == undefined || result == "") {
                    setStudentData({
                        ...studentData,
                        studentnum: '',
                        firstname: "",
                        lastname: '',
                        parentfirstname: '',
                        parentlastname: '',
                        phoneno: '',
                        email: ''
                    })
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false)
                    if (responseRs.status == "success") {
                        const parentname = responseRs?.msg?.parent_guardian_name;
                        let pfirstName = "";
                        let plastName = "";
                        if (parentname) {
                            const splitNames = parentname?.split(' ');
                            pfirstName = splitNames.length > 0 ? splitNames[0] : '';
                            plastName = splitNames.length > 1 ? splitNames.slice(1).join(' ') : '';
                        }
                        setStudentData({
                            ...studentData, studentnum: responseRs?.msg?.student_num,
                            firstname: responseRs?.msg?.device_user_first_name || '',
                            lastname: responseRs?.msg?.device_user_last_name ? responseRs?.msg?.device_user_last_name.toUpperCase() : '',
                            parentfirstname: pfirstName,
                            parentlastname: plastName,
                            phoneno: responseRs?.msg?.parent_phone_number,
                            email: responseRs?.msg?.parent_guardian_email
                        })
                        setdisableInputs(false)
                        setnostudenterror("");
                    } else {
                        setStudentData({
                            ...studentData,
                            firstname: "",
                            lastname: '',
                            parentfirstname: '',
                            parentlastname: '',
                            phoneno: '',
                            email: ''
                        })
                        setdisableInputs(false)
                        setnostudenterror("This student ID was not found in K12Tech's system. Verify the information is correct and then press 'Verify Details' to save this student's information to Rocket.")
                    }
                }
            });
        }
    }
    function handleRecaptchaChange(value) {
        setCaptchaKey(value);
    }
    function calculateProratedCost(Price) {
        const enrollmentDate = new Date(props.startdate);
        const actualPrice = Price;
        const PlanYear = enrollmentDate.getFullYear();
        const nextYearCutoffDate = new Date(PlanYear + 1, 0, 1);
        var proratedCost = 0;
        const currentDate = new Date();
        if (enrollmentDate > nextYearCutoffDate || currentDate > nextYearCutoffDate) {
            proratedCost = actualPrice * 0.60;
            setCutPriceFlag(1)
        } else {
            proratedCost = actualPrice;
        }
        setTotalPrice(proratedCost)
    }
    async function ValidateDetailsCode() {
        var isFormValid = CheckValidation({ formID: 'addProtectionForm' });
        if (CaptchaKey == "") {
            setblankCaptcha("Before you proceed, please complete the captcha");
            isFormValid = false;
        } else {
            setblankCaptcha("");
        }
        if (studentData.studentnum.trim() == "") {
            setstudentnumerror("*required");
            isFormValid = false;
        } else {
            setstudentnumerror("");
        }
        if (!isValidEmail(studentData.email)) {
            setEmailrror("*Invalid Email");
            isFormValid = false;
        } else {
            setEmailrror("");
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            SchoolID: props.SchoolId,
            sFirstName: studentData.firstname,
            sLastName: studentData.lastname,
            pFirstName: studentData.parentfirstname,
            pLastName: studentData.parentlastname,
            phoneNumber: studentData.phoneno,
            Email: studentData.email,
            Grade: props.planGradeVal,
            StudentNo: studentData.studentnum,
            Total: totalPrice ? totalPrice?.toFixed(2) : '0',
            PlanId: props.planid,
            Captcha: CaptchaKey,
            coverage_purchased: 1,
            PolicyAgree: props.PolicyAgreeFlag,
            Flag: props.planflag
        });
        await ApiPostCallAuthKey("/purchasePlanjasonValidation", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setPayLinkBtnShow(true)
                    setDisabledData(true)
                    setValidateError('Your information has been verified. You can go ahead and click on the "Proceed to Pay" button to complete the payment and purchase the plan.')
                    setValidateColor('greenText')
                    setTempId(responseRs.tempId)
                }
                else {
                    setValidateError(responseRs.msg);
                    setPayLinkBtnShow(false)
                    setDisabledData(false)
                    setValidateColor('redText')
                    setTempId('')
                }
            }
        });
    }
    const stripeSecretKey = process.env.REACT_APP_SECRET_Stripe_KEY;
    const createCheckoutSession = async () => {
        const dollarsToCents = (amountInDollars) => {
            return Math.round(amountInDollars * 100);
        };
        const dynamicAmount = totalPrice ? totalPrice?.toFixed(2) : '0';
        const url = process.env.REACT_APP_STRIPE_API_KEY;
        const successUrl = `${process.env.REACT_APP_Redirecturi}payment/${TempId}?session_id={CHECKOUT_SESSION_ID}`;
        const cancelUrl = 'https://rocket.k12techrepairs.com/';
        const currency = 'usd';
        const productName = props.planname;
        const unitAmount = dollarsToCents(dynamicAmount)
        const quantity = 1;
        const formData = new URLSearchParams();
        formData.append('mode', 'payment');
        formData.append('success_url', successUrl);
        formData.append('cancel_url', cancelUrl);
        formData.append('line_items[0][price_data][currency]', currency);
        formData.append('line_items[0][price_data][product_data][name]', productName);
        formData.append('line_items[0][price_data][unit_amount]', unitAmount);
        formData.append('line_items[0][quantity]', quantity);
        formData.append('customer_email', studentData?.email);

        formData.append('metadata[student_number]', studentData?.studentnum);
        formData.append('metadata[school_name]', props.SchoolName);
        formData.append('metadata[school_id]', props.SchoolId);
        formData.append('metadata[first_name]', studentData.firstname);
        formData.append('metadata[last_name]', studentData.lastname);
        formData.append('metadata[plan_id]', props.planid);
        formData.append('metadata[paid_amount]', dynamicAmount);
        formData.append('metadata[grade]', props.planGradeVal);
        setLoading(true);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
                body: formData.toString(),
            });
            if (!response.ok) {
                throw new Error('Failed to create Checkout Session');
            }
            const data = await response.json();
            if (data.status === 'open' && data.url) {
                setLoading(false);
                window.location.href = data.url;
            } else {
                setLoading(false);
                console.error('Invalid Checkout Session status or missing URL');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error creating Checkout Session:', error.message);
        }
    };
    async function SubmitCall() {
        var isFormValid = CheckValidation({ formID: 'midyeardiv' });
        if (!isFormValid) return;
        var raw = JSON.stringify({
            schoolid: props.SchoolId,
            studentnum: StudentNo1,
            planid: props.planid,
            accesscode: AccessCode,
        });
        await ApiPostCallAuthKey("/verifyAccessCode", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const ResponseRs = JSON.parse(result)
                if (ResponseRs.status == "success") {
                    setdisableInputs(false)
                    var studentInfo = ResponseRs?.studentdata
                    props.setEnrollmentTypeFlag(1);
                    const splitNames = studentInfo?.parent_guardian_name?.split(' ');
                    let pfirstName = splitNames ? splitNames[0] : null;
                    let plastName = splitNames ? splitNames.slice(1).join(' ').toUpperCase() : null;
                    if (studentInfo) {
                        setStudentData({
                            ...studentData, studentnum: StudentNo1, firstname: studentInfo?.device_user_first_name, lastname: studentInfo?.device_user_last_name?.toUpperCase(),
                            parentfirstname: pfirstName, parentlastname: plastName, phoneno: studentInfo?.parent_phone_number,
                            email: studentInfo?.parent_guardian_email
                        })
                    } else {
                        setStudentData({
                            ...studentData, studentnum: StudentNo1
                        })
                    }

                    setStudentNumberFlag(1)
                    setActualPrice(totalPrice);
                    calculateProratedCost(totalPrice);
                }
                else {
                    setErrormsgFormidYear(ResponseRs.msg)
                }
            }
        });
    }
    return (
        <div>
            {Loading ? <ShowLoder /> : <HideLoder />}
            <div>
                {props.EnrollmentTypeFlag == 1 && (
                    <>
                        <div>
                            <div className='col-12 pb-2'>
                                <label className='signup-font'>COVERAGE BY DEVICE</label>&nbsp;&nbsp;&nbsp;
                                <label className='font-18'>
                                    <b style={{ color: '#4f4f4f' }}>
                                        {CutPriceFlag == 1 && (
                                            <label className={`${ActualPrice != '' ? '' : 'd-none'} grayColor pe-2`}>
                                                <strike>${ActualPrice ? ActualPrice?.toFixed(2) : 0}</strike>
                                            </label>
                                        )}
                                        ${totalPrice ? totalPrice.toFixed(2) : '0'}
                                    </b>
                                </label>
                                {CutPriceFlag == 1 && (
                                    <div className="col-12 m-0 mt-2 zoom-in-out-box ">
                                        <span className="signuplink font-13">Since your enrollment is after January 1st, your cost has been adjusted to 60% of the standard rate. </span>
                                    </div>
                                )}
                            </div>
                            <div className={`row  pb-3 pt-3 ${DisabledData == true ? 'disabled-form' : ''}`}>
                                <div className='col-12 text-start  pb-1 fw-600'>Student No. *</div>
                                {studentNumberFlag == 1 ?
                                    <div className={`col-md-6 `}>
                                        <input type="text" autoComplete='off' placeholder='Student No.' name='City' className="form-control"
                                            value={studentData.studentnum} onChange={(e) => { setStudentData({ ...studentData, studentnum: e.target.value.toUpperCase() }) }} disabled />
                                    </div>
                                    :
                                    <div className={`row col-md-9 `}>
                                        <div className='col-md-8'>
                                            <input className="form-control" autoComplete="off" type="text" placeholder="Student No." name="studentnum"
                                                value={studentData.studentnum} onKeyUp={clearStudentData}
                                                onChange={(e) => setStudentData({ ...studentData, studentnum: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='col-12 BorderBtn text-center m-0 pt-2' onClick={(e) => Searchfunction(studentData.studentnum)}>
                                                <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
                                            </label>
                                        </div>
                                        <div className='col-12'>
                                            <span className="Emailinvalid">
                                                {studentnumerror}
                                            </span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <span className="Emailinvalid pt-2">
                                {nostudenterror}
                            </span>
                            <div className='col-12 pt-4'>
                                <label className='fw-600'>How to find the student number? :</label> <label className='redText'>{props.Description || '-'}</label>
                            </div>
                            <div className={`col-12 py-3 ${DisabledData == true ? 'disabled-form' : ''}`}>
                                <div className='col-12 text-start px-1 fw-600'>Student Name*</div>
                                <div className='row pt-1'>
                                    <div className="col-md-6">
                                        <input type="text" autoComplete='off' required name='firstname' disabled={disableInputs} className="form-control" placeholder='First Name'
                                            value={studentData.firstname} onChange={(e) => { setStudentData({ ...studentData, firstname: e.target.value }); }} />
                                        <span className="form-text invalid-feedback"> *required </span>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" autoComplete='off' name='lastname' required disabled={disableInputs} className="form-control" placeholder='Last Name'
                                            value={studentData.lastname} onChange={(e) => { setStudentData({ ...studentData, lastname: e.target.value.toUpperCase() }) }} />
                                        <span className="form-text invalid-feedback">  *required </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`py-3 ${DisabledData == true ? 'disabled-form' : ''}`}>
                                <div className='col-12 text-start px-1 fw-600'>Parent Name *</div>
                                <div className='row pt-1'>
                                    <div className={`col-md-6`}>
                                        <input type="text" autoComplete='off' name='pfname' disabled={disableInputs} required className="form-control" placeholder='First Name'
                                            value={studentData.parentfirstname} onChange={(e) => { setStudentData({ ...studentData, parentfirstname: e.target.value }) }} />
                                        <span className="form-text invalid-feedback"> *required </span>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" autoComplete='off' name='plname' disabled={disableInputs} required className="form-control" placeholder='Last Name'
                                            value={studentData.parentlastname} onChange={(e) => { setStudentData({ ...studentData, parentlastname: e.target.value.toUpperCase() }) }} />
                                        <span className="form-text invalid-feedback">  *required  </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <>
                                <div className={`col-md-6 py-3 ${DisabledData == true ? 'disabled-form' : ''}`}>
                                    <div className='col-12 text-start px-1 pb-1 fw-600'>Parent Contact #</div>
                                    <input type="text" autoComplete='off' placeholder='Phone No.' name='contactno' disabled={disableInputs} className="form-control"
                                        value={studentData.phoneno} onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setStudentData({ ...studentData, phoneno: e.target.value });
                                            }

                                        }} />
                                </div>
                                <div className={`col-md-6 py-3 ${DisabledData == true ? 'disabled-form' : ''}`}>
                                    <div className='col-12 text-start px-1 pb-1 fw-600'>Parent Email *</div>
                                    <input type="text" autoComplete='off' placeholder='Email' name='email' disabled={disableInputs} className="form-control"
                                        value={studentData.email} onChange={(e) => { setStudentData({ ...studentData, email: e.target.value }) }} />
                                    <span className="Emailinvalid">{Emailerror}</span>
                                </div>
                                <div className='col-12 py-2 text-center'>
                                    <label className='signup-font'>Total : </label>&nbsp;&nbsp;&nbsp;<label className='font-18'><b style={{ color: '#4f4f4f' }}>${totalPrice ? totalPrice?.toFixed(2) : '0'} </b></label>
                                </div>
                            </>
                            <div className="col-md-4 offset-md-4 text-center pb-2">
                                <div className="d-flex justify-content-center my-3">
                                    <ReCAPTCHA sitekey={captchasitekey} onChange={handleRecaptchaChange} />
                                </div>
                                <div className="text-center">
                                    <span style={{ fontSize: '.875em', color: '#dc3545' }}>{blankCaptcha}</span>
                                </div>
                            </div>
                            {props.PolicyAgreeFlag == 1 &&
                                <div className='col-12 text-center redText pb-2'>
                                    By Continuing, you confirm that you have read our Fair Use Policy and agree to it.
                                </div>
                            }
                            <button type='submit' className={`SaveBtn px-5 col-md-4 offset-md-4 ${DisabledData == true ? 'disabled-form' : ''}`} onClick={(e) => ValidateDetailsCode()}>Verify Details</button>
                            <div className={`${ValidateColor} py-2 text-center`}>{ValidateError}</div>
                            {PayLinkBtnShow == true && (
                                <button type='submit' className='SaveBtn my-3 px-4 col-md-4 offset-md-4' onClick={(e) => { createCheckoutSession() }}><b>Proceed to Pay</b></button>
                            )}
                            <label className="mb-4 text-center cursor-pointer">
                                <u onClick={(e) => window.location.reload()}>Cancel</u>
                            </label>
                        </div>
                        <div className='col-12 text-center px-0 pb-5'>
                            If you have any questions about our agreement, please don't hesitate to <span className='whats-cover-text fw-600 cursor-pointer' onClick={(e) => window.open(`/contact-us/${props.SchoolId}/${props.planid}`, 'blank')}><u>contact us</u></span>.
                        </div>
                        {PayLinkBtnShow == true && (
                            <div className='col-12 text-center mt-2'>
                                <span className="font-13 contentClr">  Powered by <img src='/images/stripeIcon.svg' className='img-fluid ' />   </span>
                            </div>
                        )}
                    </>
                )}

                {props.EnrollmentTypeFlag == 2 && (
                    <>
                        <div className='row parent' id="midyeardiv" >
                            <div className="col-md-6 pb-3 pt-4">
                                <div className='col-12 text-start px-1 pb-1 fw-600'>Student No. *</div>
                                <input type="text" autoComplete='off' placeholder='Student No.' name='City' required className="form-control"
                                    value={StudentNo1} onChange={(e) => { setStudentNo1(e.target.value.toUpperCase()) }} />
                                <span className="form-text invalid-feedback">  *required   </span>
                            </div>
                            <div className="col-md-6 pb-3 pt-4">
                                <div className='col-12 text-start px-1 pb-1 fw-600'>Enter Access Code provided by the school * </div>
                                <input type="text" autoComplete='off' placeholder='Enter Access Code' name='City' required className="form-control"
                                    value={AccessCode} onChange={(e) => { setAccessCode(e.target.value) }} />
                                <span className="form-text invalid-feedback">  *required </span>
                            </div>
                            <div className='redText'>{ErrormsgFormidYear}</div>
                            <div className='text-center'>
                                <button className='SaveBtn m-2 px-4' onClick={(e) => SubmitCall(e)}>Submit</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div >
    )
}

export default YesContractOption
