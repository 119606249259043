import React, { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import { ApiPostCallAuthKey } from '../JS/Connector';
import Cookies from 'js-cookie';
import { HideLoder, ShowLoder } from '../JS/Common';

export default function GoogleOauth(props) {
    const [loading, setLoading] = useState(false);
    const login = useGoogleLogin({
        onSuccess: tokenResponse => { fetchUserProfile(tokenResponse.access_token); },
        accessType: 'offline',
        prompt: 'consent',
        includeGrantedScopes: true,
        fetchBasicProfile: true,
    });
    const fetchUserProfile = async (accessToken) => {
        try {
            const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const profile = await response.json();
                if (props.flag == "signin") {
                    onSigninSuccess(profile, accessToken);
                } else if (props.pageflag == "helpdesk") {
                    onHelpdeskSuccess(profile, accessToken);
                } else {
                    onSupportTicketSuccess(profile, accessToken);
                }
            } else {
                console.error('Failed to fetch user profile');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };
    const onSigninSuccess = async (res, accessToken) => {
        setLoading(true)
        var raw = JSON.stringify({
            name: res.name,
            email: res.email,
            googleId: res.sub,
            microsoftId: null,
            accessToken: accessToken,
            flag: 1,
            password: null
        });
        await ApiPostCallAuthKey("/UserLogin", raw).then((result) => {
            if (result == undefined || result == "") {
                console.error('Login Failed!');
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false)
                if (responseRs.status == "success") {
                    const expires = 1;
                    Cookies.set('CsvUserId', responseRs.msg.id, { expires });
                    Cookies.set('schoolid', responseRs.msg.school_id, { expires });
                    Cookies.set('ciphertext', responseRs.msg.remember_token, { expires });
                    if (responseRs.schoolDetails != null) {
                        Cookies.set('Schoolnumber', responseRs.schoolDetails.school_number, { expires });
                    }
                    Cookies.set('ShippingType', responseRs?.schoolDetails?.shipping_type, { expires });
                    props.alertcolor("green");
                    props.alertmsg(`Authentication successfull! Redirecting to the home page...`);
                    setTimeout(() => {
                        if (responseRs.msg.access_type == 5 || responseRs.msg.access_type == 6) {
                            window.location = '/school-tickets';
                        } else {
                            window.location.href = '/';
                        }
                    }, 1500);
                }
                else {
                    props.alertcolor("red");
                    props.alertmsg(responseRs.response);
                }
            }

        });
    }
    const onSupportTicketSuccess = async (res, accessToken) => {
        var rawForShow = JSON.stringify({
            name: res.name,
            email: res.email,
            accessToken: accessToken,
        });
        var raw = JSON.stringify({
            SchoolNum: props.schoolnumber,
            LoginEmail: res.email
        })
        await ApiPostCallAuthKey("/ssoLoginValidation", raw).then((result) => {
            if (result == undefined || result == "") {
                console.error('Login Failed!');
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false)
                if (responseRs.status == "success") {
                    const expires = 1;
                    Cookies.set('supportTicketDetails', rawForShow, { expires });
                    props.alertcolor('green')
                    props.alertmsg(responseRs.msg)
                    setTimeout(function () {
                        props.setLoginFlag(0)
                    }, 2500);
                }
                else {
                    props.alertcolor('red')
                    props.alertmsg(responseRs.msg)
                }
            }
        });
    }
    const onHelpdeskSuccess = async (res, accessToken) => {
        var rawForShow = JSON.stringify({
            name: res.name,
            email: res.email,
            accessToken: accessToken,
        });
        var raw = JSON.stringify({
            SchoolNum: props.schoolnumber,
            LoginEmail: res.email
        })
        await ApiPostCallAuthKey("/helpdeskLoginValidation", raw).then((result) => {
            if (result == undefined || result == "") {
                console.error('Login Failed!');
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false)
                if (responseRs.status == "success") {
                    const expires = 1;
                    Cookies.set('helpTicketDetails', rawForShow, { expires });
                    props.alertcolor('green')
                    props.alertmsg(responseRs.msg)
                    setTimeout(function () {
                        props.setLoginFlag(0)
                    }, 2500);
                }
                else {
                    props.alertcolor('red')
                    props.alertmsg(responseRs.msg)
                }
            }
        });
    }
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <button onClick={() => login()} className="MicrosoftGoogleBtn">
                <img src="/images/GoogleBtn.svg" className="img-fluid pe-2" /> Google
            </button>
        </div>
    )
}
