import React, { useEffect, useState } from 'react'
import { CamelCaseWithSpaces, formatDateToMMDDYYYY, isValidEmail, LogInSchoolID, MMDDYYYY, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import Pagination from 'react-js-pagination';
import { saveAs } from 'file-saver'
import CustomizationPopup from '../../Components/CustomizationPopup';
import AlertsComp from '../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';
import PageHead from '../../Components/PageHead';
import Tab from '../../Components/Tab';
import AlertPopup from '../../Components/AlertPopup';
import Popup from '../../Components/Popup';
import { CheckValidation } from '../../Components/Validations';
import { faFileInvoiceDollar, faHandHoldingDollar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function CoveragePurchased() {
    const navigate = useNavigate();
    const [allData, setAllData] = useState({
        SortbyFlag: 'desc',
        searchstringForCover: '',
        searchstringForUnCover: '',
        searchstringForExpired: '',
    })
    const [ParentalTabs, setParentalTabs] = useState({
        Coverage: "active",
        Uncovered: "",
        Expired: ""
    });
    const [loading, setLoading] = useState(false);
    const [Emailerror, setEmailerror] = useState('');
    const [Alerts, setAlerts] = useState("");
    const [PurchasedGridData, setPurchasedGridData] = useState([]);
    const [ActualEnrollment, setActualEnrollment] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const itemsPerPage = LimitForPagination;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const partListt = PurchasedGridData.slice(indexOfFirstItem, indexOfLastItem);
    const showPagination = PurchasedGridData.length > 0;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [SortbyKey, setSortbyKey] = useState(null);
    const [ApproveRejectModal, setApproveRejectModal] = useState(false);
    const [ApproveRejectAlert, setApproveRejectAlert] = useState(false);
    const [RefundPopup, setRefundPopup] = useState(false);
    const [effectExecuted, setEffectExecuted] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [ShowCreateBatchButton, setShowCreateBatchButton] = useState(false);
    const [StudentIdForRefund, setStudentIdForRefund] = useState('');
    const [studentnumflag, setstudentnumflag] = useState(2);
    const [oldstudentnum, setoldstudentnu] = useState('');
    const [ShowYesDiv, setShowYesDiv] = useState(false);
    const [studentDetails, setstudentDetails] = useState([]);
    const [radioBtnFlag, setradioBtnFlag] = useState(0);
    const [tempFlag, settempFlag] = useState(0);
    const [ApproveDescription, setApproveDescription] =
        useState("This student is currently not enrolled in your school. If you select 'Approve,' the student will be added to your school, and the plan will be assigned to them. If you select 'Don't Approve,' the student's enrollment will not be approved, and the payment will be refunded. Do you wish to proceed?");
    const [studentData, setStudentData] = useState({
        studentnum: '',
        firstname: "",
        lastname: '',
        parentfirstname: '',
        parentlastname: '',
        phoneno: '',
        email: ''
    })
    const [allocatedEnrollmentID, setallocatedEnrollmentID] = useState("");
    useEffect(() => {
        if (!effectExecuted) {
            ApiGetHeaders(44, setColumnNames)
            setEffectExecuted(true);
        }
    }, [columnNames, effectExecuted]);
    useEffect(() => {
        if (ParentalTabs.Coverage == "active") {
            PurchasedData(1, allData.searchstringForCover || null, SortbyKey || null)
        } else if (ParentalTabs.Uncovered == "active") {
            PurchasedData(2, allData.searchstringForUnCover || null, SortbyKey || null)
        } else {
            PurchasedData(3, allData.searchstringForExpired || null, SortbyKey || null)
        }
    }, [currentPage, LimitForPagination])
    async function PurchasedData(flag, searchstring, sortbykey) {
        if (!searchstring) searchstring = null;
        if (sortbykey) allData.SortbyFlag = allData.SortbyFlag == "desc" ? "asc" : "desc";
        var joinedString = null;
        var newsortbykey = sortbykey;
        if (sortbykey == null) {
            newsortbykey = 'id';
        }
        if (columnNames.length > 0) {
            var joinedString = columnNames.join('~');
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/parentalCoveragePurchased/" + LogInSchoolID + '&' + searchstring + '&' + newsortbykey + '&' + allData.SortbyFlag + "?data=" + joinedString).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    if (flag == 1) {
                        setPurchasedGridData(responseRs.coverPlan.length ? responseRs.coverPlan : [])
                        setActualEnrollment(responseRs.coverPlanActualenrollment);
                    } else if (flag == 2) {
                        setPurchasedGridData(responseRs.uncoverPlan.length ? responseRs.uncoverPlan : [])
                        setActualEnrollment(responseRs.uncoverPlanActualenrollment)
                    } else if (flag == 3) {
                        setPurchasedGridData(responseRs.pastcoveredPlan.length ? responseRs.pastcoveredPlan : [])
                        setActualEnrollment(responseRs.pastcoveredPlanActualenrollment)
                    }
                }
            });
        }, searchstring != null ? 1000 : 0);
    }
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "plan_name": item.plan?.plan_name || '-',
            "student_num": item.student?.student_num || '-',
            "paid_amount": `$${item.paid_amount || '0'}`,
            "purchased_at": MMDDYYYY(item.created_at) || '-',
            "plan_num": item.plan?.plan_num || '-',
            "contact_name": item.plan?.contact_name || '-',
            "contact_email": item.plan?.contact_email || '-',
            "estimated_enrollment": item.plan?.estimated_enrollment || '-',
            "devices_not_leave_school": item.plan?.devices_not_leave_school || '-',
            "grade": item.grade || '-',
            "email": item.email || '-',
            "student_name": item.student_name || '-',
            "fair_use_acknowledgement": item.enrollment_status == 2 ? '-' : item.policy_agree == 1 ? 'Yes' : 'No',
        };
        return contentMap[columnName] || null;
    }
    const handleSortClick = (item) => {
        if (item !== 0) {
            if (ParentalTabs.Coverage == "active") {
                PurchasedData(1, allData.searchstringForCover, item)
            } else if (ParentalTabs.Uncovered == "active") {
                PurchasedData(2, allData.searchstringForUnCover, item)
            } else {
                PurchasedData(3, allData.searchstringForExpired, item)
            }
            setSortbyKey(item)
        }
    };
    async function GetStudentData(student_id) {
        setShowYesDiv(false);
        setradioBtnFlag(0)
        settempFlag(0)
        setApproveDescription("This student is currently not enrolled in your school. If you select 'Approve,' the student will be added to your school, and the plan will be assigned to them. If you select 'Don't Approve,' the student's enrollment will not be approved, and the payment will be refunded. Do you wish to proceed?")
        setLoading(true);
        await ApiGetCall("/StudentDataByID/" + student_id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                const parentNameParts = responseRs.msg.parent_guardian_name ? responseRs.msg.parent_guardian_name.split(' ') : [];
                const parentFirstName = parentNameParts[0] || "";
                const parentLastName = parentNameParts[1] || "";
                setLoading(false);
                setStudentData({
                    ...studentData,
                    studentnum: responseRs.msg.student_num,
                    firstname: responseRs.msg.device_user_first_name,
                    lastname: responseRs.msg.device_user_last_name,
                    parentfirstname: parentFirstName,
                    parentlastname: parentLastName,
                    phoneno: responseRs.msg.parent_phone_number,
                    email: responseRs.msg.parent_guardian_email
                });
                setoldstudentnu(responseRs.msg.student_num);
            }
        });
    }
    async function ApproveorRejectPurchasesCall(MassOrSingleApprove, ApproveRejectStatus, radioBtnFlag) {
        if (MassOrSingleApprove == 2) {
            var isFormValid = CheckValidation({ formID: 'ApproveStudent' });
            if (!isValidEmail(studentData.email)) {
                setEmailerror("*Invalid Email");
                isFormValid = false;
            } else {
                setEmailerror("");
            }
            if (!isFormValid) {
                return;
            }
        }
        setLoading(true)
        const studentId = selectedIds.length > 0 ? selectedIds[0] : null;
        var studentflag = studentnumflag;
        if (oldstudentnum == studentData.studentnum) {
            studentflag = 2;
        }
        var raw = JSON.stringify({
            studentIDArray: MassOrSingleApprove == 1 ? selectedIds.map((id) => ({ id: id })) : null,
            studentID: MassOrSingleApprove == 1 ? null : studentId,
            schoolID: LogInSchoolID,
            studentFirstName: studentData?.firstname,
            studentLastName: studentData?.lastname,
            parentFirstName: studentData?.parentfirstname,
            parentLastName: studentData?.parentlastname,
            parentContact: studentData?.phoneno,
            parentEmail: studentData?.email,
            approveFlag: MassOrSingleApprove,
            approveRejectStatus: ApproveRejectStatus,
            studentNum: studentData.studentnum,
            student_num_flag: studentflag,
            allocatedEnrollmentID: allocatedEnrollmentID,
            verificationFlag: radioBtnFlag,
            oldstudentnum: oldstudentnum
        });
        await ApiPostCall("/ApproveStudent", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    if (ApproveRejectStatus == 2) {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={"User rejected with the attached plan."} />);
                        setTimeout(() => {
                            setApproveRejectModal(false);
                            if (ParentalTabs.Coverage == "active") {
                                PurchasedData(1, allData.searchstringForCover, SortbyKey)
                            } else if (ParentalTabs.Uncovered == "active") {
                                PurchasedData(2, allData.searchstringForUnCover, SortbyKey)
                            } else {
                                PurchasedData(3, allData.searchstringForExpired, SortbyKey)
                            }
                            setAlerts(<AlertsComp show={false} />);
                        }, 2500);
                    }
                    else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"User approved with the attached plan successfully."} />);
                        setTimeout(() => {
                            setApproveRejectModal(false);
                            navigate('/users');
                            setAlerts(<AlertsComp show={false} />);
                        }, 1500);
                    }
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }

    async function verifyStudentNum() {
        var raw = JSON.stringify({
            schoolID: LogInSchoolID,
            studentNum: studentData.studentnum
        });
        await ApiPostCall("/verifyStudentNum", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setradioBtnFlag(responseRs.radioBtnFlag);
                    if (responseRs.radioBtnFlag == 0) {
                        setApproveDescription("This user is already in the system. If you select 'Verify & Approve', the student's information will be updated. Are you sure you want to proceed?")
                        setShowYesDiv(true);
                        setstudentDetails(responseRs.studentDetails);
                        settempFlag(1);
                        if (tempFlag == 1) {
                            ApproveorRejectPurchasesCall(2, 1, responseRs.radioBtnFlag);
                        }
                    } else if (responseRs.radioBtnFlag == 1) {
                        settempFlag(0);
                        setApproveDescription("This student already exists in the system and has an active coverage plan. Please choose another student.")
                        setShowYesDiv(false);
                        setAlerts(<AlertsComp show={true} variant="success" msg={responseRs.msg} />);
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />);
                        }, 1500);
                    } else if (responseRs.radioBtnFlag == 2) {
                        settempFlag(0);
                        setApproveDescription("This student is currently not enrolled in your school. If you select 'Approve,' the student will be added to your school, and the plan will be assigned to them. If you select 'Don't Approve,' the student's enrollment will not be approved, and the payment will be refunded. Do you wish to proceed?")
                        setShowYesDiv(false);
                        ApproveorRejectPurchasesCall(2, 1, responseRs.radioBtnFlag);
                    } else if (responseRs.radioBtnFlag == 3) {
                        settempFlag(0);
                        setApproveDescription("This student does not exist in the system and was not added to the purchase plan. Please provide a valid student number.")
                        setShowYesDiv(false);
                        setAlerts(<AlertsComp show={true} variant="success" msg={responseRs.msg} />);
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />);
                        }, 1500);
                    }
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="success" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }

    async function funcRefund() {
        setLoading(true);
        var raw = JSON.stringify({
            studentID: StudentIdForRefund,
            schoolID: LogInSchoolID,
        });
        await ApiPostCall("/MarkAsRefund", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Refund Successfully!"} />);
                    setTimeout(() => {
                        setRefundPopup(false);
                        if (ParentalTabs.Coverage == "active") {
                            PurchasedData(1, allData.searchstringForCover, SortbyKey)
                        } else if (ParentalTabs.Uncovered == "active") {
                            PurchasedData(2, allData.searchstringForUnCover, SortbyKey)
                        } else {
                            PurchasedData(3, allData.searchstringForExpired, SortbyKey)
                        }
                        setAlerts(<AlertsComp show={false} />);
                    }, 2500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 2500);
                }
            }
        });
    }

    const handleCheckboxForApprove = (e, id) => {
        if (e.target.checked) {
            setSelectedIds(prevIds => {
                const newIds = [...prevIds, id];
                const allVisibleIds = partListt.filter(item => item?.student?.status === 2).map(item => item?.student?.id);
                const allVisibleSelected = allVisibleIds.every(visibleId => newIds.includes(visibleId));
                setIsAllSelected(allVisibleSelected);
                setShowCreateBatchButton(newIds.length > 0);
                return newIds;
            });
        } else {
            setSelectedIds(prevIds => {
                const newIds = prevIds.filter(item => item !== id);
                setIsAllSelected(false);
                setShowCreateBatchButton(newIds.length > 0);
                return newIds;
            });
        }
    };
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allVisibleIds = partListt.filter(item => item?.student?.status == 2).map(item => item?.student?.id);
            setSelectedIds(allVisibleIds);
            setShowCreateBatchButton(true);
        } else {
            setSelectedIds([]);
            setShowCreateBatchButton(false);
        }
        setIsAllSelected(e.target.checked);
    };
    const handleApproveButtonClick = (id) => {
        setSelectedIds(prevIds => {
            if (!prevIds.includes(id)) {
                return [...prevIds, id];
            }
            return prevIds;
        });
        setShowCreateBatchButton(true);
        setApproveRejectModal(true);
    };
    function handleSearchChange(e) {
        if (ParentalTabs.Coverage == "active") {
            setCurrentPage(1);
            PurchasedData(1, e.target.value, null);
            setAllData({ ...allData, searchstringForCover: e.target.value });
        } else if (ParentalTabs.Uncovered == "active") {
            setCurrentPage(1);
            PurchasedData(2, e.target.value, null);
            setAllData({ ...allData, searchstringForUnCover: e.target.value });
        } else {
            setCurrentPage(1);
            PurchasedData(3, e.target.value, null);
            setAllData({ ...allData, searchstringForExpired: e.target.value });
        }
    }
    function handleTabClick(tabType) {
        if (tabType == "coverage") {
            PurchasedData(1, null, null);
            setParentalTabs({ ...ParentalTabs, Coverage: "active", Uncovered: "", Expired: "" });
            setCurrentPage(1);
            setSelectedIds([]);
            setShowCreateBatchButton(false);
            setIsAllSelected(false)
        } else if (tabType == "uncovered") {
            PurchasedData(2, null, null);
            setParentalTabs({ ...ParentalTabs, Coverage: "", Uncovered: "active", Expired: "" });
            setCurrentPage(1);
            setSelectedIds([]);
            setShowCreateBatchButton(false);
            setIsAllSelected(false)
        } else if (tabType == "expired") {
            PurchasedData(3, null, null);
            setParentalTabs({ ...ParentalTabs, Coverage: "", Uncovered: "", Expired: "active" });
            setCurrentPage(1);
            setSelectedIds([]);
            setShowCreateBatchButton(false);
            setIsAllSelected(false)
        }
    }
    const modalbody = (
        <form id="ApproveStudent" className="parent">
            <div className="row">
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-1'>Student No. *</div>
                    <input className="form-control" autoComplete="off" type="text" name="studentnum"
                        required value={studentData?.studentnum}
                        onChange={(e) => { setstudentnumflag(1); setStudentData({ ...studentData, studentnum: e.target.value.toUpperCase() }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-'>Student First Name *</div>
                    <input className="form-control" required autoComplete="off" type="text" name="StudentFirstName" value={studentData.firstname} onChange={(e) => { setStudentData({ ...studentData, firstname: e.target.value.toUpperCase() }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-1'>Student Last Name *</div>
                    <input className="form-control" required autoComplete="off" type="text" name="StudentLastName" value={studentData.lastname} onChange={(e) => { setStudentData({ ...studentData, lastname: e.target.value.toUpperCase() }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-1'>Parent First Name *</div>
                    <input className="form-control" required autoComplete="off" type="text" name="ParentFirstName" value={studentData.parentfirstname} onChange={(e) => { setStudentData({ ...studentData, parentfirstname: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-1'>Parent Last Name *</div>
                    <input className="form-control" required autoComplete="off" type="text" name="ParentLastName" value={studentData.parentlastname} onChange={(e) => { setStudentData({ ...studentData, parentlastname: e.target.value.toUpperCase() }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-1'>Parent Contact *</div>
                    <input className="form-control" required autoComplete="off" type="text" name="ParentContact" value={studentData.phoneno} onChange={(e) => { setStudentData({ ...studentData, phoneno: e.target.value }) }} />
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className="col-md-4 py-2">
                    <div className='col-12 text-start px-1 pb-1'>Parent Email *</div>
                    <input className="form-control" autoComplete="off" type="text" name="ParentEmail" value={studentData.email} onChange={(e) => { setStudentData({ ...studentData, email: e.target.value }) }} />
                    <span className="Emailinvalid">{Emailerror}</span>
                </div>
            </div>
            <div className='py-4 '>
                <label className='redText fw-600 text-center'>{ApproveDescription}</label>
                {ShowYesDiv &&
                    <>
                        <b className='pt-3'>*This is Existing User Details.</b>
                        <div className='row mt-1'>
                            <div className='col-md-6'>
                                Student No. :&nbsp;{studentDetails?.student_num || '-'}
                            </div>
                            <div className='col-md-6'>
                                First Name :&nbsp;{studentDetails?.device_user_first_name || '-'}
                            </div>
                            <div className='col-md-6'>
                                Last Name :&nbsp;{studentDetails?.device_user_last_name || '-'}
                            </div>
                            <div className='col-md-6'>
                                Parent Name :&nbsp;{studentDetails?.parent_guardian_name || '-'}
                            </div>
                            <div className='col-md-6'>
                                Parent Email :&nbsp;{studentDetails?.parent_guardian_email || '-'}
                            </div>
                            <div className='col-md-6'>
                                Parent Contact :&nbsp;{studentDetails?.parent_phone_number || '-'}
                            </div>
                        </div>
                    </>
                }
            </div>

        </form>
    )
    async function ExportPurchasedData() {
        setLoading(true);
        await ApiGetCall("/exportPurchaseData/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length > 0) {
                    DownloadCsv(responseRs);
                } else {
                    DownloadCsv([]);
                }
                setLoading(false);
            }
        });
    }
    const DownloadCsv = (CsvData) => {
        const csvContent = [];
        const PurchaseMap = [];
        CsvData?.forEach((item) => {
            const rowData = {
                'Plan Name': item.plan_name,
                'Student Number': item.student_num,
                'Student First Name': item.student_first_name,
                'Student Last Name': item.student_last_name,
                'Purchase/Entry Date': formatDateToMMDDYYYY(item.purchase_date),
                'Acknowledgement of Fair Use Policy': item.Acknowledgement_of_policy ? 'Yes' : 'No',
                'Covered': item.purchase_plan_flag == 1 ? 'Yes' : 'No',
            };
            PurchaseMap.push(rowData);
        });

        if (PurchaseMap.length > 0) {
            const headers = ['Plan Name', 'Student Number', 'Student First Name', 'Student Last Name', 'Purchase/Entry Date', 'Acknowledgement of Fair Use Policy', 'Covered'];
            csvContent.push(headers);
            PurchaseMap.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'CoverageData.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                setAlerts(<AlertsComp show={false} />);
            }, 2500);
        }
    };

    function downloadreceipt(receipt_url) {
        if (receipt_url) {
            window.open(receipt_url, "_blank")
        } else {
            setAlerts(<AlertsComp show={true} variant="danger" msg={"No Receipt Found"} />);
            setTimeout(() => {
                setAlerts(<AlertsComp show={false} />);
            }, 2500);
        }
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Purchases"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox mt-2 p-3">
                <div className="row px-0 pb-2">
                    <div className='col-md-9'>
                        <ul className="nav nav-tabs">
                            <Tab isActive={ParentalTabs.Coverage == 'active'}
                                label="Coverage Purchased" onClick={() => handleTabClick('coverage')} col={'col-md-4'} />
                            <Tab isActive={ParentalTabs.Uncovered == 'active'}
                                label="Uncovered Students" onClick={() => handleTabClick('uncovered')} col={'col-md-3'} />
                            <Tab isActive={ParentalTabs.Expired == 'active'}
                                label="Coverage Expired" onClick={() => handleTabClick('expired')} col={'col-md-3'} />
                        </ul>
                    </div>
                    <div className="col-md-3 text-end px-0 mx-0">
                        <label className="BorderBtn text-end  mt-0"
                            onClick={(e) => ExportPurchasedData()}
                        >
                            <img src="/images/ExportInventory.svg" className="cursor-pointer pe-2"
                                height="15px" />
                            Export Coverage Data
                        </label>

                    </div>
                    <div className='col-12 text-end'>
                        {ShowCreateBatchButton == true && (
                            <label className="BorderBtn text-end  mt-0"
                                onClick={(e) => setApproveRejectAlert(true)}
                            >
                                <img src="images/TransactionIcon.svg" className="cursor-pointer pe-2" title="Approve User"
                                    height="15px" />
                                Approve
                            </label>
                        )}
                    </div>
                    <div className='col-12 text-end'>
                        Actual Enrollment : <b> {ActualEnrollment}</b>
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                <th scope="col">
                                    {ParentalTabs.Expired == "" && (
                                        PurchasedGridData.length > 0 && partListt.some(item => item?.student?.status == 2) && (
                                            <>
                                                <input className='form-check-input' name="allselect" type="checkbox"
                                                    checked={isAllSelected}
                                                    onChange={handleSelectAll} />
                                            </>
                                        )
                                    )}
                                </th>
                                {columnNames?.map((item) => {
                                    let columnName = item.replace(/_/g, ' ');
                                    return (
                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                            {CamelCaseWithSpaces(columnName)}
                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                        </th>
                                    );
                                })}
                                {ParentalTabs.Coverage == "active" ?
                                    <th scope="col" className='fw-600 '>Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                    :
                                    <th scope="col" className='fw-600 '><img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {partListt.length != 0 ?
                                partListt.map((item, i) => {
                                    return <tr key={i} className={`OddEvenClass tableBorderHide`}>
                                        <td className='py-12'>
                                            {ParentalTabs.Expired == "" && (
                                                item?.student?.status == 2 && item?.refund_status == 0 && (
                                                    <input
                                                        className='form-check-input'
                                                        type="checkbox"
                                                        checked={selectedIds.includes(item?.student?.id)}
                                                        onChange={(e) => handleCheckboxForApprove(e, item?.student?.id)}
                                                    />
                                                )
                                            )}
                                        </td>
                                        {columnNames?.map(header => (
                                            <td className="py-12" key={header} >
                                                {renderContentForColumn(header, item)}
                                            </td>
                                        ))}
                                        <td scope="col" className="py-12 d-flex">
                                            {ParentalTabs.Coverage == "active" && (
                                                <>

                                                    <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ height: '20px' }} className=" px-1 cursor-pointer" title="Preview Stripe Receipt"
                                                        onClick={(e) => downloadreceipt(item.receipt_url)} />
                                                    <img src="/images/previewpdficon.svg" className=" px-1" title="Preview Purchased Coverage" height='20px'
                                                        onClick={(e) => saveAs(BucketS3Link + item.enrollment_pdf)} />
                                                </>
                                            )}

                                            {ParentalTabs.Coverage == "active" && (

                                                ParentalTabs.Expired == "" && (
                                                    item?.student?.status == 2 && item?.refund_status == 0 ? (
                                                        <FontAwesomeIcon icon={faThumbsUp} className="cursor-pointer px-1" title="Approve Student" style={{ height: '20px' }}
                                                            onClick={(e) => { handleApproveButtonClick(item?.student?.id); GetStudentData(item?.student?.id); setallocatedEnrollmentID(item?.id) }} />
                                                    ) : item?.refund_status == 0 ? (
                                                        <FontAwesomeIcon icon={faHandHoldingDollar} className="cursor-pointer px-1" title="Refund" style={{ height: '20px' }}
                                                            onClick={(e) => { setStudentIdForRefund(item?.student?.id); setRefundPopup(true) }} />
                                                    ) : <>-</>
                                                )

                                            )}
                                            {ParentalTabs.Uncovered == "active" && (
                                                item?.student?.status == 2 && item?.refund_status == 0 ? (
                                                    <FontAwesomeIcon icon={faThumbsUp} className="cursor-pointer px-1" title="Approve Student" style={{ height: '20px' }}
                                                        onClick={(e) => { handleApproveButtonClick(item?.student?.id); GetStudentData(item?.student?.id); setallocatedEnrollmentID(item?.id) }} />
                                                )
                                                    :
                                                    <>-</>)}
                                        </td>
                                    </tr>
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={15}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={PurchasedGridData.length}
                        pageRangeDisplayed={15}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}

            </div>
            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Purchases'} tabFlag={44} GetCall={() => ApiGetHeaders(44, setColumnNames)} />}
            {ApproveRejectModal &&
                <Popup isshow={ApproveRejectModal} size={"lg"} title={"Approve Student"}
                    closePopup={(e) => { setApproveRejectModal(false) }} modalBody={modalbody}
                    visibleSaveBtn={true} btnText={"Verify & Approve"} handleSave={() => verifyStudentNum()}
                    extraBtn={true} ExtraText={"Don't Approve"}
                    handleSaveForExtra={() => ApproveorRejectPurchasesCall(2, 2, radioBtnFlag)} />
            }
            {ApproveRejectAlert &&
                <AlertPopup isshow={ApproveRejectAlert} img={'/images/greenInfo.svg'}
                    headingtext={`Approving multiple students may result in wrong information being put into Rocket. Please double check information before proceeding, or approve individually. Do you want to approve it?`} text={''}
                    cancelcall={(e) => { setApproveRejectAlert(false) }}
                    canceltext={"Cancel"} successtext={"Yes"} successcall={() => ApproveorRejectPurchasesCall(1, null, radioBtnFlag)} btntype={'SaveBtn'} />
            }
            {RefundPopup &&
                <AlertPopup isshow={RefundPopup} img={'/images/greenInfo.svg'}
                    headingtext={`Are you sure you want to refund the student for this plan? If you proceed with the refund, the plan will be revoked. Do you wish to continue?`} text={''}
                    cancelcall={(e) => { setRefundPopup(false) }}
                    canceltext={"Cancel"} successtext={"Yes"} successcall={() => funcRefund()} btntype={'SaveBtn'} />
            }
        </React.Fragment>

    )
}


export default CoveragePurchased

