import React, { useEffect, useRef, useState } from 'react'
import { ApiPostCallAuthKey } from '../JS/Connector';
import { getUrlParameter, HideLoder, MMDDYYYY, ShowLoder } from '../JS/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver'
function PaymentSuccessPage() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const TempId = pathArray[2];
    const session_id = getUrlParameter("session_id")
    const [PlanData, setPlanData] = useState([])
    const [receiptUrl, setreceiptUrl] = useState(null)
    const [loading, setLoading] = useState(false);
    const receiptRef = useRef();
    const downloadButtonRef = useRef();
    const url = process.env.REACT_APP_STRIPE_API_KEY;
    useEffect(() => {
        const fetchData = async () => {
            if (session_id) {
                try {
                    const sessionResponse = await fetchCheckoutSession(session_id);
                    const paymentIntentId = sessionResponse.payment_intent;

                    const paymentIntentResponse = await fetchPaymentIntent(paymentIntentId);
                    const chargeId = paymentIntentResponse.latest_charge;

                    const chargeResponse = await fetchChargeDetails(chargeId);
                    const receiptUrl = chargeResponse.receipt_url;
                    setreceiptUrl(receiptUrl)
                    handleSubmit(receiptUrl);
                } catch (error) {
                    console.error('Error fetching payment intent:', error);
                    alert('Failed to fetch payment details.');
                }
            }
        };

        fetchData();
    }, [session_id]);

    const fetchCheckoutSession = async () => {
        const response = await fetch(`${url}/${session_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_Stripe_KEY}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch Checkout Session');
        }
        return await response.json();
    };
    const fetchPaymentIntent = async (paymentIntentId) => {
        const paymentIntentUrl = `https://api.stripe.com/v1/payment_intents/${paymentIntentId}`;

        const response = await fetch(paymentIntentUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_Stripe_KEY}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch Payment Intent');
        }
        return await response.json();
    };
    const fetchChargeDetails = async (chargeId) => {
        const chargeUrl = `https://api.stripe.com/v1/charges/${chargeId}`;

        const response = await fetch(chargeUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_Stripe_KEY}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch Charge details');
        }
        return await response.json();
    };
    const handleSubmit = async (receiptUrl) => {
        var raw = JSON.stringify({
            TempID: TempId,
            ReceiptURL: receiptUrl
        });
        setLoading(true)
        await ApiPostCallAuthKey("/purchasePlan", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setPlanData(responseRs.enrollment)
                }
                else {
                    window.location.href = "https://www.k12techrepairs.com/";
                }
            }
        });
    }
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='container m-auto pt-3' style={{ display: 'flex', alignItems: 'center', minHeight: '100vh' }}>
                <div className='col-md-6 offset-md-3 GridBox' ref={receiptRef}>
                    <div className='payment-header-style'>
                        <div className='col-12 p-2 text-end payment-schoolName-style'>{PlanData?.school?.name}</div>
                    </div>
                    <div className='Payment-screen-style p-4'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{ color: 'white' }}><div className='font-13'>Receipt for</div><div>{PlanData?.student?.device_user_first_name + ' ' + (PlanData?.student?.device_user_middle_name || '') + ' ' + PlanData?.student?.device_user_last_name}</div></div>
                            <div style={{ color: 'white' }}>
                                ${PlanData.paid_amount}
                            </div>
                        </div>
                    </div>
                    <div className='row font-15 px-5 pt-3 pb-3'>
                        <div className='col-md-6 py-2'>
                            <div className='pt-1 label-style-receipt'> Student Number</div>
                            <div className='fw-600'>{PlanData?.student?.student_num}</div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='pt-1 label-style-receipt'>Plan Name</div>
                            <div className='fw-600'>{PlanData?.plan?.plan_name}</div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='pt-1 label-style-receipt'>Plan Number</div>
                            <div className='fw-600'>{PlanData?.plan?.plan_num}</div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='pt-1 label-style-receipt'>Sign Up Start Date</div>
                            <div className='fw-600'>{MMDDYYYY(PlanData?.plan?.start_date)}</div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='pt-1 label-style-receipt'>Contact Email</div>
                            <div className='fw-600'>{PlanData?.plan?.contact_email}</div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='pt-1 label-style-receipt'>Contact Name</div>
                            <div className='fw-600'>{PlanData?.plan?.contact_name}</div>
                        </div>
                        <div className='col-md-12 label-style-receipt pt-2'> Device Models</div>
                        <div className='col-md-12 fw-600'> {PlanData?.plan?.device_models}</div>
                        <div className='d-flex justify-content-center'><hr className='w-100' /></div>
                        <div className='col-md-12 text-end font-14'>
                            <label className='label-style-receipt'>Paid Amount :</label> <label className='fw-600'>${PlanData.paid_amount}</label>
                        </div>
                    </div>
                    <div className='col-12 text-center' ref={downloadButtonRef}>
                        <div className="col-12 pb-4 pt-1">
                            <label title="Download Receipt" className="cursor-pointer d-flex align-items-center justify-content-center" onClick={(e) => { saveAs(receiptUrl); window.location.href = "https://www.k12techrepairs.com/"; }}>
                                <FontAwesomeIcon icon={faDownload} style={{ color: "#878484", height: "16px" }} className="img-fluid" />
                                <span className="ms-3" style={{ color: "#878484", fontWeight: "600", fontSize: "16px" }}>Download Receipt</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PaymentSuccessPage
