import { useState, useEffect } from "react"
import { ShowLoder, HideLoder, RemoveAllCookies, isValidEmail } from "../../JS/Common";
import { ApiPostCallAuthKey } from "../../JS/Connector";
import { CheckValidation } from "../../Components/Validations";
import ReCAPTCHA from "react-google-recaptcha";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export function Signup() {
    const [FormData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        SchoolName: "",
        Password: "",
        ConfirmPassword: ""
    });
    const [AlertMsgs, setAlertMsgs] = useState("");
    const [ContinueFlag, setContinueFlag] = useState(0);
    const [AlertColor, setAlertColor] = useState("");
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [ErrorMsg, setErrorMsg] = useState('');
    const [CaptchaKey, setCaptchaKey] = useState("");
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [emailError, setemailError] = useState("");
    const [ShowForm, setShowForm] = useState(1);
    const [RegisterFlag, setRegisterFlag] = useState(1);
    const [ConfirmPwdError, setConfirmPwdError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    var captchasitekey = process.env.REACT_APP_Captcha_Sitekey;
    useEffect(() => {
        RemoveAllCookies();
    }, []);
    async function SignUp() {
        var isFormValid = CheckValidation({ formID: 'RegisterForm' });
        if (!isChecked) {
            setErrorMsg("It is required to check our Terms & Conditions above and agree to it.")
            isFormValid = false
        }
        if (CaptchaKey == "") {
            setblankCaptcha("Before you proceed, please complete the captcha");
            isFormValid = false;
        } else {
            setblankCaptcha("");
        }
        if (FormData.Email != "") {
            if (!isValidEmail(FormData.Email)) {
                setemailError('*Invalid Email')
                isFormValid = false;
            } else {
                setemailError("");
            }
        }
        if (FormData.Password != FormData.ConfirmPassword) {
            setConfirmPwdError("Password must be same")
            isFormValid = false;
            return;
        } else {
            setConfirmPwdError("");
        }
        if (FormData.Password != "") {
            if (FormData.Password.length < 8) {
                setConfirmPwdError("Password should be between 8-16 characters!");
                isFormValid = false;
            } else {
                setConfirmPwdError("");
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            FirstName: FormData.FirstName,
            lastname: FormData.LastName,
            email: FormData.Email,
            schoolname: FormData.SchoolName,
            Captcha: CaptchaKey,
            RegisterFlag: RegisterFlag,
            Password: FormData.Password || null,
            ConfirmPassword: FormData.ConfirmPassword || null
        });
        if (isChecked == true) {
            await ApiPostCallAuthKey("/addUsers", raw).then((result) => {
                if (result == undefined || result == "") {
                    setAlertColor("red");
                    setAlertMsgs("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    if (responseRs.status == "success") {
                        setShowForm(2);
                        setAlertMsgs(responseRs.msg);
                        setAlertColor("green");
                    } else {
                        setShowForm(1);
                        setAlertMsgs(responseRs.msg);
                        setContinueFlag(responseRs.flag);
                        setAlertColor("red");
                    }

                }
            });
        } else {
            setLoading(false);
        }
    }

    async function ContinueSignUp() {
        setLoading(true);
        var raw = JSON.stringify({
            FirstName: FormData.FirstName,
            lastname: FormData.LastName,
            email: FormData.Email,
            schoolname: FormData.SchoolName,
            Password: FormData.Password || null,
        })
        await ApiPostCallAuthKey("/sameDomainSchoolContinueClick", raw).then((result) => {
            if (result == undefined || result == "") {
                setAlertColor("red");
                setAlertMsgs("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setShowForm(2);
                    setAlertMsgs(responseRs.msg);
                    setAlertColor("green")
                } else {
                    setShowForm(1);
                    setAlertMsgs(responseRs.msg);
                    setAlertColor("red")
                }
            }
        });
    }

    function handleRecaptchaChange(value) {
        setCaptchaKey(value);
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv" style={{ overflowY: "scroll" }}>
                <div className="signupcard mx-auto mt-3">
                    <div className="p-4 col-12">
                        {ShowForm == 1 && (
                            <div className="col-12 text-center">
                                <div style={{ color: `${AlertColor}` }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                            </div>
                        )}
                        <div className="mt-1">
                            <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                        </div>
                        {ShowForm == 1 ?
                            <div className="mt-2 row parent" id="RegisterForm">
                                <div className='col-md-6 pt-4'>
                                    <div className='col-12'>
                                        <input type="text" placeholder="First Name*" autoComplete="off" name='firstname' className="form-control" required value={FormData.FirstName}
                                            onChange={(e) => setFormData({ ...FormData, FirstName: e.target.value })} />
                                        <span className="form-text text-start invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6 pt-4'>
                                    <div className='col-12'>
                                        <input type="text" placeholder="Last Name*" autoComplete="off" name='lastname' className="form-control " required value={FormData.LastName}
                                            onChange={(e) => setFormData({ ...FormData, LastName: e.target.value })} />
                                        <span className="form-text text-start invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6 pt-3'>
                                    <div className='col-12 text-start'>
                                        <input type="text" placeholder="Email*" autoComplete="off" name='email' className="form-control " required value={FormData.Email}
                                            onChange={(e) => setFormData({ ...FormData, Email: e.target.value })} />
                                        <span className="text-start Emailinvalid">{emailError}</span>
                                        <span className="form-text text-start invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6 pt-3'>
                                    <div className='col-12'>
                                        <input type="text" placeholder="School Name*" autoComplete="off" name='Schoolname' className="form-control " required value={FormData.SchoolName}
                                            onChange={(e) => setFormData({ ...FormData, SchoolName: e.target.value })} />
                                        <span className="form-text text-start invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 pt-3 text-start">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name='Password' checked={RegisterFlag == 1} onChange={(e) => setRegisterFlag(1)} />
                                        <label className="form-check-label">Register with Google/Outlook (No Password Required) </label>
                                    </div>
                                    <div className="form-check mt-1">
                                        <input className="form-check-input" type="radio" name='Password' checked={RegisterFlag == 2} onChange={(e) => setRegisterFlag(2)} />
                                        <label className="form-check-label">Register with Password</label>
                                    </div>
                                </div>
                                {RegisterFlag == 2 && (
                                    <div className="row">
                                        <div className='col-md-6 pt-3'>
                                            <div className='col-12 text-start position-relative'>
                                                <input type={showPassword ? 'text' : 'password'} placeholder="Password*" autoComplete="off" name='password' className="form-control customFormControl" required value={FormData.Password}
                                                    onChange={(e) => setFormData({ ...FormData, Password: e.target.value })} />
                                                <span className="form-text text-start invalid-feedback">
                                                    *required
                                                </span>
                                                <span
                                                    className="eye-icon"
                                                    onClick={togglePasswordVisibility}
                                                    style={{ cursor: 'pointer', position: 'absolute', right: '2%', top: '11px' }}
                                                >
                                                    {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-md-6 pt-3'>
                                            <div className='col-12 position-relative'>
                                                <input type={showConfirmPassword ? 'text' : 'password'} placeholder="Confirm Password*" autoComplete="off" name='confirmpwd' className="form-control customFormControl" required value={FormData.ConfirmPassword}
                                                    onChange={(e) => setFormData({ ...FormData, ConfirmPassword: e.target.value })} />
                                                <span className="form-text text-start invalid-feedback">
                                                    *required
                                                </span>
                                                <span
                                                    className="eye-icon"
                                                    onClick={toggleConfirmPasswordVisibility}
                                                    style={{ cursor: 'pointer', position: 'absolute', right: '2%', top: '11px' }}
                                                >
                                                    {showConfirmPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                                </span>
                                            </div>
                                        </div>
                                        <span className="Emailinvalid text-start">
                                            {ConfirmPwdError}
                                        </span>
                                    </div>
                                )}
                                <div className='col-12 text-center pt-4 ps-4 centerstyle font-12 d-flex align-items-center'>
                                    <input className='form-check-input mt-0 me-2 cursor-pointer' type="checkbox" checked={isChecked} onChange={(e) => { setIsChecked(e.target.checked); setErrorMsg('') }} />
                                    <label className="text-start text-gray-billable"> I have carefully read and agree to the <span className="cursor-pointer" onClick={(e) => window.open('/terms-of-service')} style={{ color: '#2cc3a9' }}> Terms of Service</span> and
                                        <span className="cursor-pointer" onClick={(e) => window.open('/privacy-policy')} style={{ color: '#2cc3a9' }}> Privacy Policy</span></label>
                                </div>
                                <span className="Emailinvalid">{ErrorMsg}</span>
                                <div className="d-flex justify-content-center pt-3">
                                    <ReCAPTCHA sitekey={captchasitekey} onChange={handleRecaptchaChange} />
                                </div>
                                <div className="text-center">
                                    <span className="Emailinvalid">{blankCaptcha}</span>
                                </div>

                                {ContinueFlag == 1 ?
                                    <div className="col-12 text-center pt-3">
                                        <button className='CreateButton col-md-3' onClick={ContinueSignUp}>Continue</button>
                                        <label className='ms-2 cursor-pointer' onClick={() => window.location.reload()}><u>Reset</u></label>
                                    </div>
                                    :
                                    <div className='col-12 pt-3'>
                                        <button className='CreateButton col-md-6' onClick={SignUp}>Create a District or School Account</button>
                                        <br />
                                        <span style={{ fontSize: "11px" }}>*This is for creating accounts at the school-wide level, rather than for individual accounts.</span>
                                    </div>
                                }
                            </div>
                            :
                            <div className="col-12 text-center pt-5 pb-3">
                                <div style={{ color: 'green' }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                            </div>
                        }


                        <div className="row pt-3">
                            <div className="col-md-6 font-13">
                                Register for our demo <a className="cursor-pointer" style={{ color: "rgb(30 191 162)" }} href="https://k12tech.clickmeeting.com/k12tech-rocket-demo/register" target="_blank">Here</a>
                            </div>
                            <div className="col-md-6 text-center font-13">
                                Already a User?<a className="ps-2 cursor-pointer" style={{ color: "rgb(30 191 162)" }} href="/">Sign In</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
