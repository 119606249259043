import React, { useEffect, useState } from 'react'
import { HideLoder, ShowLoder } from '../../JS/Common';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { CheckValidation } from '../../Components/Validations';
import { useNavigate } from 'react-router-dom';
import { ApiPostCallAuthKey } from '../../JS/Connector';

export default function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [AlertMsgs, setAlertMsgs] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [ConfirmPwdError, setConfirmPwdError] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [ExpiredLink, setExpiredLink] = useState(false);
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const encryptid = pathArray[2];
    const [FormData, setFormData] = useState({
        Password: '',
        ConfirmPassword: '',
        Email: "",
        SchoolId: ''
    })
    useEffect(() => {
        var raw = JSON.stringify({
            linkParams: encryptid,
        });
        ApiPostCallAuthKey("/checkResetPasswordLink", raw).then((result) => {
            if (result == undefined || result == "") {
                setAlertColor("red");
                setAlertMsgs("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setFormData({ ...FormData, Email: responseRs.email, SchoolId: responseRs.school_id })
                    setExpiredLink(false);
                } else {
                    alert("The link has expired!")
                    setExpiredLink(true);
                }

            }
        });
    }, [])
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    async function handleResetPwd() {
        if (!ExpiredLink) {
            var isFormValid = CheckValidation({ formID: 'resetpasswordform' });
            if (FormData.Password != FormData.ConfirmPassword) {
                setConfirmPwdError("Password must be same")
                isFormValid = false;
                return;
            } else {
                setConfirmPwdError("");
            }
            if (FormData.Password != "") {
                if (FormData.Password.length < 8) {
                    setConfirmPwdError("Password should be between 8-16 characters!");
                    isFormValid = false;
                } else {
                    setConfirmPwdError("");
                }
            }
            if (!isFormValid) return;
            setLoading(true);
            var raw = JSON.stringify({
                password: FormData.Password,
                email: FormData.Email,
                school_id: FormData.SchoolId
            });
            await ApiPostCallAuthKey("/resetPasswordClick", raw).then((result) => {
                if (result == undefined || result == "") {
                    setAlertColor("red");
                    setAlertMsgs("Something went wrong");
                } else {
                    setLoading(false);
                    if (result == "success") {
                        setAlertMsgs(`Password Reset Successfully!`);
                        setAlertColor("green");
                        setTimeout(() => {
                            navigate('/');
                        }, 1500);
                    } else {
                        setAlertMsgs(result);
                        setAlertColor("red");
                    }

                }
            });
        } else {
            setAlertMsgs("The link has expired!");
            setAlertColor("red");
        }

    }
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <div className="col-12 text-center">
                            <div style={{ color: `${AlertColor}` }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                        </div>
                        <div className="my-4">
                            <img src="/images/NewLogo.svg" className="img-fluid" alt="Logo" />
                        </div>
                        <div className='col-12 text-center py-2'>
                            <h4 className="fw-600">Reset Password</h4>
                        </div>
                        <div className="row parent" id="resetpasswordform">
                            <div className='col-md-11 offset-md-1 pt-3'>
                                <div className='col-12 text-start position-relative'>
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Password*" autoComplete="off" name='password' className="form-control customFormControl" required value={FormData.Password}
                                        onChange={(e) => setFormData({ ...FormData, Password: e.target.value })} />
                                    <span className="form-text text-start invalid-feedback">
                                        *required
                                    </span>
                                    <span
                                        className="eye-icon"
                                        onClick={togglePasswordVisibility}
                                        style={{ cursor: 'pointer', position: 'absolute', right: '2%', top: '11px' }}
                                    >
                                        {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-11 offset-md-1 pt-3'>
                                <div className='col-12 position-relative'>
                                    <input type={showConfirmPassword ? 'text' : 'password'} placeholder="Confirm Password*" autoComplete="off" name='confirmpwd' className="form-control customFormControl" required value={FormData.ConfirmPassword}
                                        onChange={(e) => setFormData({ ...FormData, ConfirmPassword: e.target.value })} />
                                    <span className="form-text text-start invalid-feedback">
                                        *required
                                    </span>
                                    <span
                                        className="eye-icon"
                                        onClick={toggleConfirmPasswordVisibility}
                                        style={{ cursor: 'pointer', position: 'absolute', right: '2%', top: '11px' }}
                                    >
                                        {showConfirmPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-11 offset-md-1 pt-3'>
                                <span className="Emailinvalid text-start">
                                    {ConfirmPwdError}
                                </span>
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button className='CreateButton col-md-5' onClick={(e) => handleResetPwd()}>Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
